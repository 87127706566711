import { ViewBasic } from '@quatrecentquatre/manage-me'
import { ProjectFormService } from '../services/project-form'
import { bindAll } from 'underscore'

export class DonationForm extends ViewBasic {
  constructor(options) {
    super(options)

    this.firstnameField = this.el.querySelector('#donation-form-lastname')
    this.lastnameField = this.el.querySelector('#donation-form-firstname')
    this.emailField = this.el.querySelector('#donation-form-email')
    this.messageField = this.el.querySelector(
      '#donation-form-project-description',
    )
    this.submitBtn = this.el.querySelector('.submit_btn')
  }

  initialize() {
    bindAll(this, [
      'clickHandler',
      'checkFirstname',
      'checkLastname',
      'checkEmail',
      'checkMessage',
    ])
    this.addEvents()

    // ContactFormService for fetch.
    this.service = new ProjectFormService()
  }

  addEvents() {
    this.submitBtn.addEventListener('click', this.clickHandler)
    this.firstnameField.addEventListener('change', this.checkFirstname)
    this.lastnameField.addEventListener('change', this.checkLastname)
    this.emailField.addEventListener('change', this.checkEmail)
    this.messageField.addEventListener('change', this.checkMessage)
  }

  removeEvents() {
    this.submitBtn.removeEventListener('click', this.clickHandler)
    this.firstnameField.removeEventListener('change', this.checkFirstname)
    this.lastnameField.removeEventListener('change', this.checkLastname)
    this.emailField.removeEventListener('change', this.checkEmail)
    this.messageField.removeEventListener('change', this.checkMessage)
  }

  /**
   * Press submit button
   * @param e
   */
  clickHandler(e) {
    e.preventDefault()

    let scope = this
    scope.el.querySelector('form').className = ''
    scope.el.querySelector('form').classList.add('loading')

    //validate the form
    if (this.verifyFields()) {
      //send form if valid.

      grecaptcha.ready(function () {
        //execute recaptcha
        grecaptcha
          .execute(window.SETTINGS.RECAPTCHA_SITE_KEY, { action: 'submit' })
          .then(function (token) {
            //save token for backend
            scope.el.querySelector('[name="g-recaptcha-response"]').value =
              token

            //submit form with fetch api.
            scope.service
              .sendProjectMessage(
                scope.el.querySelector('form').getAttribute('action'),
                new FormData(scope.el.querySelector('form')),
              )
              .then((response) => {
                if (response.errors) {
                  // form submit successfully, but errors returned
                  scope.el.querySelector('form').classList.add('error')
                  scope.el.querySelector('form').classList.remove('loading')
                } else {
                  //success
                  scope.el.querySelector('form').classList.add('success')
                  scope.el.querySelector('form').classList.remove('loading')
                  scope.manageSuccess()
                }
              })
              .catch((error) => {
                //PHP error
                scope.el.querySelector('form').classList.add('error')
                scope.el.querySelector('form').classList.remove('loading')
              })
          })
      })
    }
  }

  /**
   * Shows the success message, clears all the fields and removes class "has-value" to all fields
   */
  manageSuccess() {
    this.el.querySelector('form').reset()
    let filledInputs = this.el.querySelectorAll('.has-value')
    for (let i = 0; i < filledInputs.length; i++) {
      filledInputs[i].classList.remove('has-value')
    }
  }

  /**
   * Verifies all the fields
   * @returns {boolean}
   */
  verifyFields() {
    if (
      (this.checkFirstname(),
      this.checkLastname(),
      this.checkEmail(),
      this.checkMessage())
    ) {
      return true
    }
    this.el.querySelector('form').classList.remove('loading')
    return false
  }

  /**
   * Verifies firstname field
   * @returns {boolean}
   */
  checkFirstname() {
    let valid = true

    if (this.firstnameField.value === '') {
      this.firstnameField.classList.add('error')
      valid = false
    } else {
      this.firstnameField.classList.remove('error')
    }

    return valid
  }

  /**
   * Verifies lastname field
   * @returns {boolean}
   */
  checkLastname() {
    let valid = true

    if (this.lastnameField.value === '') {
      this.lastnameField.classList.add('error')
      valid = false
    } else {
      this.lastnameField.classList.remove('error')
    }

    return valid
  }

  /**
   * Verifies email field
   * @returns {boolean}
   */
  checkEmail() {
    let valid = true
    let emailRegex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i

    if (!this.emailField.value.match(emailRegex)) {
      this.emailField.classList.add('error')
      valid = false
    } else {
      this.emailField.classList.remove('error')
    }

    return valid
  }

  /**
   * Verifies message field
   * @returns {boolean}
   */
  checkMessage() {
    let valid = true

    if (this.messageField.value === '') {
      this.messageField.classList.add('error')
      valid = false
    } else {
      this.messageField.classList.remove('error')
    }

    return valid
  }
  terminate() {
    this.removeEvents()
  }
}

Me.views['DonationForm'] = DonationForm
