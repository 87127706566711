import { ViewBasic } from '@quatrecentquatre/manage-me'
import '@quatrecentquatre/skin-me'
import { bindAll } from 'underscore'
import { FilterService } from '../services/filter'

export class Filters extends ViewBasic {
  constructor(options) {
    super(options)
    //set default variables
  }

  initialize() {
    bindAll(this, ['reset', 'search', 'querySearch', 'paginatedSearch'])

    this.disableSendingForm = false
    this.service = new FilterService()

    this.addEvents()
  }

  addEvents() {
    let scope = this
    this.el.querySelector('.all_filters').addEventListener('click', this.reset)
    this.el.querySelector('.search').addEventListener('click', this.querySearch)
    window.addEventListener('filter.Paginate', this.paginatedSearch)
    this.el.querySelectorAll('select').forEach((select) => {
      select.addEventListener('change', scope.search)
    })
  }

  removeEvents() {
    let scope = this
    this.el
      .querySelector('.all_filters')
      .removeEventListener('click', this.reset)
    this.el
      .querySelector('.search')
      .removeEventListener('click', this.querySearch)
    this.el.querySelectorAll('select').forEach((select) => {
      select.removeEventListener('change', scope.search)
    })
  }

  querySearch(e) {
    e.preventDefault()
    this.search()
  }

  search(page) {
    if (this.disableSendingForm) {
      return
    }

    if (page && !isNaN(page)) {
      this.el.querySelector('.page').value = page
    } else {
      this.el.querySelector('.page').value = '1'
    }

    this.service
      .search(
        this.el.querySelector('form').getAttribute('action'),
        new FormData(this.el.querySelector('form')),
      )
      .then((response) => {
        let customEvent = new CustomEvent('filter.showResult', {
          detail: response,
        })
        window.dispatchEvent(customEvent)
      })
  }

  paginatedSearch(e) {
    this.search(e.detail.pagination)
  }

  reset(e) {
    e.preventDefault()
    this.disableSendingForm = true
    this.el.querySelectorAll('select').forEach((select) => {
      Me.skin.getField(select).setSelection(0)
    })
    this.disableSendingForm = false
    this.search()
  }
}

Me.views['Filters'] = Filters
