import { ViewBasic } from '@quatrecentquatre/manage-me'
import { bindAll } from 'underscore'

export class SubjectList extends ViewBasic {
  constructor(options) {
    super(options)
    //set default variables
  }

  initialize() {
    bindAll(this, ['showResults'])

    this.addEvents()
  }

  addEvents() {
    let scope = this
    window.addEventListener('filter.showResult', this.showResults)

    if (this.el.querySelectorAll('.pagination-wrapper a').length != 0) {
      this.el.querySelectorAll('.pagination-wrapper a').forEach((element) => {
        element.addEventListener('click', scope.goToPage)
      })
    }
  }

  removeEvents() {}

  showResults(e) {
    let scope = this

    this.el.querySelector('.subjects-list').innerHTML =
      e.detail.data.subjectsHtml

    if (e.detail.data.paginationHtml != '') {
      if (this.el.querySelectorAll('.pagination-wrapper a').length != 0) {
        this.el.querySelectorAll('.pagination-wrapper a').forEach((element) => {
          element.removeEventListener('click', scope.goToPage)
        })
      }
      this.el.querySelector('.pagination-wrapper').innerHTML =
        e.detail.data.paginationHtml

      if (this.el.querySelectorAll('.pagination-wrapper a').length != 0) {
        this.el.querySelectorAll('.pagination-wrapper a').forEach((element) => {
          element.addEventListener('click', scope.goToPage)
        })
      }
    } else {
      this.el.querySelector('.pagination-wrapper').innerHTML = ''
    }
  }

  goToPage(e) {
    e.preventDefault()

    //get last part of the url after the 'page=' so we get the page requested in the pagination
    window.dispatchEvent(
      new CustomEvent('filter.Paginate', {
        detail: {
          pagination: e.currentTarget.getAttribute('href').split('page=')[1],
        },
      }),
    )
  }
}

Me.views['SubjectList'] = SubjectList
