import { ViewBasic } from '@quatrecentquatre/manage-me'
import Swiper, { Navigation, Pagination } from 'swiper'

export class SwiperPrograms extends ViewBasic {
  constructor(options) {
    super(options)
    //set default variables
  }

  initialize() {
    Swiper.use([Navigation, Pagination])

    this.defaultColor = '#000'

    this.programSwiper = this.el.querySelector('.swiper-container')
    // grab the amount of columns
    this.columns = parseInt(
      getComputedStyle(document.documentElement).getPropertyValue(
        '--column-amount',
      ),
    )
    this.addEvents()
  }

  addEvents() {
    let scope = this
    this.swiperControl()
  }

  removeEvents() {
    let scope = this
    this.swiperControl()
  }

  swiperControl() {
    const swiper = new Swiper(this.programSwiper, {
      direction: 'horizontal',
      slidesPerView: 'auto',
      // snap to grid layout
      spaceBetween: 16,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },

      //freeMode: true,
      //freeModeSticky: true,
    })
  }
}

Me.views['SwiperPrograms'] = SwiperPrograms
