import { ViewBasic } from '@quatrecentquatre/manage-me'
import { bindAll } from 'underscore'

export class Notification extends ViewBasic {
  constructor(options) {
    super(options)

    bindAll(this, ['clickHandler'])
  }

  initialize() {
    this.addEvents()

    if (!window.CookieManager.getCookie('ecomaris-cookie-read')) {
      //notification never interacted with, so we display it
      this.el.classList.remove('hide')
    } else if (
      window.CookieManager.getCookie('ecomaris-cookie-read') !=
      this.el.getAttribute('data-last-update')
    ) {
      //date of notification has changed, so it's a new notification.
      //remove the cookie for future use.
      window.CookieManager.setCookie('ecomaris-cookie-read', '', 0)
    }
  }

  addEvents() {
    this.el
      .querySelector('button.close')
      .addEventListener('click', this.clickHandler)
  }
  removeEvents() {
    this.el
      .querySelector('button.close')
      .removeEventListener('click', this.clickHandler)
  }

  /**
   * Save a cookie with the modification date. The notification header will not show up again unless the notification itself change by the mean of a new published date.
   * @param e: click event on close button
   */
  clickHandler(e) {
    window.CookieManager.setCookie(
      'ecomaris-cookie-read',
      this.el.getAttribute('data-last-update'),
      9999,
    )
    this.el.classList.add('hide')
  }
}

Me.views['Notification'] = Notification
