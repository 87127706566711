import { VideoPlayer } from '../../classes/video-player';
import { bindAll } from 'underscore'

export class YoutubePlayer extends VideoPlayer {
    constructor(options) {
        super(options);

        bindAll(this, ['setVideo', 'toggleVideo']);

        this.autoplay = this.el.dataset.autoplay;
        this.firstPlay = true;

        if(!document.getElementById('youtube-player-script')){
            // Load the Youtube iframe API
            let tag = document.createElement('script');
            tag.src = "https://www.youtube.com/iframe_api";
            let scriptTag = document.getElementsByTagName('script')[0];
            scriptTag.parentNode.insertBefore(tag, scriptTag);
        }
    }

    addEvents() {
        super.addEvents();
        document.addEventListener('youtubeVideoReady', this.setVideo);
    }

    removeEvents() {
        super.removeEvents();
        document.removeEventListener('youtubeVideoReady', this.setVideo);
    }

    /**
     * Start video player and hide the poster
     */

    playVideo(p_force_play = false) {
        super.playVideo();

        if(this.firstPlay){
            if(this.videoPlayer && this.videoPlayer.setVolume){
                this.videoPlayer.setVolume(50);
            }
            this.firstPlay = false;
        }

        if (this.videoPlayer) {
            this.isPlaying = true;
            this.videoPlayer.playVideo();
            if(!this.autoplay && !p_force_play){ 
                this.dispatchVideoPlaying();
            }

        }
    }

    /**
     * Stop video player and show the poster
     */
    stopVideo() {
        super.stopVideo();
        if (this.videoPlayer) {
            this.isPlaying = false;
            this.videoPlayer.stopVideo();
        }
    }

    /**
     * Pause video player and show the poster when another video starts
     */
    pauseVideo(e) {
        super.pauseVideo();
        if (this.videoPlayer) {
            if (this.isPlaying) {
                this.isPlaying = false;
                this.videoPlayer.pauseVideo();
            }
        }
    }

    /**
     * Prepare YouTube video player
     */
    setVideo(e) {
        let host = 'https://www.youtube.com';
        
        //check if we received a video id to play, otherwise we play data-video-id
        //the master video will receive in non mobile a video to play from other tiles
        let videoID = this.el.getAttribute('data-video-id');
       
        if (videoID && typeof YT !== 'undefined') {
            // if(this.videoPlayer){
            //     this.videoPlayer.stopVideo();
            // }

            this.videoPlayer = new YT.Player(this.el.querySelector('.video-player'), {
                height: 'auto',
                width: '100%',
                videoId: videoID,
                playerVars: {
                    'autoplay': this.el.dataset.autoplay,
                    'controls': this.el.dataset.autoplay ? 0 : 1,
                    'mute': this.el.dataset.autoplay,
                    'loop': this.el.dataset.autoplay,
                    'rel': 0,
                    'showinfo': this.el.dataset.autoplay ? 0 : 1,
                    'modestbranding': this.el.dataset.autoplay,
                },
                host: host,
                events: {
                    onReady: () => this.onVideoPlayerReady(),
                    onStateChange: (event) => {
                        // this event calls our play/pause if user use built-in player controls
                        switch (event.data) {
                            case YT.PlayerState.PLAYING:
                                this.playVideo();
                                break;
                            case YT.PlayerState.PAUSED:
                                this.pauseVideo();
                                break;
                            case YT.PlayerState.ENDED:
                                if(this.autoplay){
                                    this.playVideo();
                                }
                                break;
                        }
                    },
                },
            });
        }
    }
}

window.onYouTubeIframeAPIReady = function() {
    document.dispatchEvent(new Event('youtubeVideoReady'));
}

Me.views['YoutubePlayer'] = YoutubePlayer;
