import { ServiceBase } from './service.base'

export class FilterService extends ServiceBase {
  constructor(options) {
    super(options)
  }

  search(url, formData) {
    const headers = {
      'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').content,
    }

    return this.fetch('POST', url, formData, { headers: headers })
      .then((response) => {
        let dataParsed = { ...response }
        //Data treatment for unique version when retrieving a dataset.

        return Promise.resolve(dataParsed)
      })
      .catch((error) => {
        // Error management for unique behavior wherever this service is called
        return Promise.reject(error)
      })
  }
}
