import barba from '@barba/core'

const BARBA_STATUS_BEFORE = 'before'
const BARBA_STATUS_BEFORE_LEAVE = 'beforeLeave'
const BARBA_STATUS_LEAVE = 'leave'
const BARBA_STATUS_AFTER_LEAVE = 'afterLeave'
const BARBA_STATUS_BEFORE_ENTER = 'beforeEnter'
const BARBA_STATUS_ENTER = 'enter'
const BARBA_STATUS_AFTER_ENTER = 'afterEnter'
const BARBA_STATUS_AFTER = 'after'

const COL_DURATION = 0.2
const EASING_IN = 'power1.easeOut'
const EASING_OUT = 'power1.easeIn'
const STAGGER_DELAY = 0.06

const TEXT_DURATION = 2.13
const TEXT_EASING_IN = 'slow(0.1, 0.7, false)'

if (history.scrollRestoration) {
  history.scrollRestoration = 'manual'
}

document.addEventListener('DOMContentLoaded', function () {
  //deactivated for now

  // //deactivated barba because no budget to fix it in v1.0
  //@note Si besoin de réactivé, installer GSAP en suivant la doc dans la base créative du repository de la base front-end.
    return
  const transitionEl = document.querySelector('#page-transition')

  // @NOTE
  // For more information about configuration / setup and possibilities, see https://barba.js.org/docs/getstarted/intro/
  barba.init({
    debug: false,
    logLevel: 'error',
    cacheIgnore: false,
    prefetchIgnore: false,
    preventRunning: true,
    timeout: 15000,
    prevent: ({ el }) => el.classList && el.classList.contains('barba-prevent'),
    requestError: (trigger, action, url, response) => {
      // go to a custom 404 page if the user click on a link that return a 404 response status
      // if (action === 'click' && response.status && response.status === 404) {
      //     return true;
      // }
      return true

      // @NOTE
      // Prevent Barba from redirecting the user to the requested URL
      // This is equivalent to e.preventDefault() in this context
      //return false
    },
    transitions: [
      {
        name: 'simple',
        // @NOTE
        // The "to" key is to set a rule to know when this animation should run or not based on the next page namespace.
        to: {
          namespace: ['simple'],
        },
        leave(data) {
          window.dispatchEvent(
            new CustomEvent('Overlay.close', {
              detail: {
                force: true,
              },
            }),
          )
          return null /*gsap.to(data.current.container, {
            opacity: 0,
            filter: 'blur(3px)',
            transform: 'skewX(-2deg)',
            delay: 0,
            duration: 0.3,
            ease: 'power1.in',
            onComplete: function () {
              data.current.container.classList.add('hide')
            },
          })*/
        },
        enter(data) {
          return null /*gsap.from(data.next.container, {
            opacity: 0,
            filter: 'blur(2px)',
            transform: 'skewX(1deg) translateX(-20px)',
            duration: 0.5,
            ease: 'power1.out',
          })*/
        },
      },
    ],
  })

  //for dev purpose only, prevent yii admin bar to make redirect call with barba js.
  //timeout is needed because the debug toolbar is added after our script here.
  setTimeout(function () {
    document
      .querySelectorAll('#yii-debug-toolbar a')
      .forEach((item) => item.setAttribute('data-barba-prevent', 'self'))
  }, 1500)

  barba.hooks.before((data) => {
    document.querySelector('html').classList.add('in-transition')
    /*gsap.to(document.querySelector('#site-ctn'), {
      filter: 'blur(2px)',
      opacity: '0.8',
      delay: 0,
      duration: 0.3,
      ease: 'power1.in',
    })*/
  })

  barba.hooks.afterLeave((data) => {
    window.scrollTo(0, 0)

    // @NOTE
    // Get DOM of next and current page to compare the list of script found in the page to add or to remove them.
    const currentDOM = new DOMParser().parseFromString(
      data.current.html,
      'text/html',
    )
    const nextDOM = new DOMParser().parseFromString(data.next.html, 'text/html')
    const currentArrayScript = Array.from(
      currentDOM.querySelectorAll('body>script'),
    )
    const nextArrayScript = Array.from(nextDOM.querySelectorAll('body>script'))
    //@NOTE
    // Parse all scripts from the next page to detect if they doesn't exit in the current page to add them
    let scriptNodeToAdd = []
    let scriptNodeToRemove = []
    let scriptRemoved = 0
    let scriptLoaded = 0

    nextArrayScript.forEach((nScript, nIndex) => {
      if (nScript.attributes && nScript.attributes.src) {
        let nScriptDom = document.querySelector(
          `script[src="${nScript.attributes.src.value}"]`,
        )

        if (!nScriptDom) {
          // Append script
          let scriptNode = document.createElement('script')
          scriptNode.async = nScript.attributes.async
          scriptNode.src = nScript.attributes.src.value
          scriptNodeToAdd.push(scriptNode)
        }
      }
    })

    //@NOTE Parse all scripts from the current page to detect if they doesn't exit in the current page to remove them
    currentArrayScript.forEach((cScript, nIndex) => {
      if (cScript.attributes && cScript.attributes.src) {
        let cSprintDom = nextDOM.querySelector(
          `script[src="${cScript.attributes.src.value}"]`,
        )

        if (!cSprintDom) {
          // Delete script
          scriptNodeToRemove.push(
            document.querySelector(
              `script[src="${cScript.attributes.src.value}"]`,
            ),
          )
        }
      }
    })

    // @NOTE
    // Create two promise for add and remove to be sure all scripts will be loaded or removed and then we can go to the next state of barba
    let addScripts = new Promise((resolve, reject) => {
      if (scriptNodeToAdd.length > 0) {
        scriptNodeToAdd.forEach((node, index) => {
          document.querySelector('body').appendChild(node)
          node.addEventListener('load', () => {
            scriptLoaded++

            if (scriptLoaded === scriptNodeToAdd.length) {
              resolve()
            }
          })
        })
      } else {
        resolve()
      }
    })

    let removeScripts = new Promise((resolve, reject) => {
      if (scriptNodeToRemove.length > 0) {
        scriptNodeToRemove.forEach((node, index) => {
          document.querySelector('body').removeChild(node)
          scriptRemoved++
          if (scriptRemoved === scriptNodeToRemove.length) {
            resolve()
          }
        })
      } else {
        resolve()
      }
    })

    // Return Promise since Barba works with promises. Won't go to the next state until all promises are done.
    return Promise.all([addScripts, removeScripts])
  })

  barba.hooks.after((data) => {
    // @NOTE
    // Dispatch new page is now the current page
    // We init again all manage me views at the same time to restart and clear all events

    window.dispatchEvent(
      new CustomEvent('pageTransition', {
        detail: {
          status: BARBA_STATUS_AFTER,
        },
      }),
    )

    setTimeout(function () {
      Me.manage.initViews()
      Me.skin.initFields()
    }, 200)
    window.dispatchEvent(new Event('resize'))
    document.querySelector('html').classList.remove('no-scroll')
  })
})
