import { ViewBasic } from '@quatrecentquatre/manage-me'
import Swiper from 'swiper'

export class SwiperTestimonials extends ViewBasic {
  constructor(options) {
    super(options)
    //set default variables
  }

  initialize() {
    this.newSwiper = this.el.querySelector('.swiper-container')
    // grab the amount of columns
    this.columns = parseInt(
      getComputedStyle(document.documentElement).getPropertyValue(
        '--column-amount',
      ),
    )
    this.addEvents()
  }

  addEvents() {
    this.swiperControl()
  }

  removeEvents() {
    this.swiperControl()
  }
  swiperControl() {
    const swiper = new Swiper(this.newSwiper, {
      direction: 'horizontal',
      slidesPerView: 'auto',
      // snap to grid layout
      snapToGrid: true,
      snapGrid: 'closest',
      spaceBetween: 16,
      grid: {
        columns: this.columns,
      },

      // freeMode: true,
      // freeModeSticky: true,
    })
  }
}

Me.views['SwiperTestimonials'] = SwiperTestimonials
