import {ViewBasic} from '@quatrecentquatre/manage-me';
import Swiper from 'swiper';


export class SwiperGallery extends ViewBasic {
    constructor(options) {
        super(options);
        //set default variables
    }

    initialize() {
        this.newSwiper = this.el.querySelector('.swiper-container');
        this.slides = this.el.querySelectorAll('.gallery-slide');

        // lightbox
        this.lightbox = this.el.querySelector('.lightbox');
        this.lightboxImg = this.el.querySelector('.lightbox-ctn img');

        // grab the amount of columns
        this.columns = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--column-amount'));
        this.addEvents();
    }

    addEvents() {
        if (!this.el.querySelector('.masonry')) {
            this.swiperControl();
        }
        this.slides.forEach((slide) => {
            slide.addEventListener('click', this.triggerLightbox.bind(this))
        });
        this.lightbox.addEventListener('click', (e) => {
            if (e.target === this.lightbox) {
                this.closeLightbox();
            }
        });
    }

    removeEvents() {
        this.swiperControl();
        this.slides.forEach((slide) => {
            slide.removeEventListener('click', this.triggerLightbox.bind(this));
        });
        this.lightbox.removeEventListener('click', (e) => {
            if (e.target === this.lightbox) {
                this.closeLightbox();
            }
        });
    }

    swiperControl() {
        this.swiper = new Swiper(this.newSwiper, {
            direction: 'horizontal',
            slidesPerView: 'auto',
            snapToGrid: true,
            snapGrid: 'closest',
            grid: {
                columns: this.columns,
            },
        });
    }

    triggerLightbox(e) {
        e.preventDefault();
        const target = e.target
        const clickedSlideImg = target.parentNode.querySelector('img');
        this.openLightbox(clickedSlideImg.src);
    }

    openLightbox(imgSrc) {
        this.lightboxImg.src = imgSrc;
        this.lightbox.classList.add('open');
    }

    closeLightbox() {
        this.lightboxImg.src = '';
        this.lightbox.classList.remove('open');
    }


}

Me.views['SwiperGallery'] = SwiperGallery;
