import { ViewBasic } from '@quatrecentquatre/manage-me';
import { bindAll } from 'underscore'

export class VideoPlayer extends ViewBasic {
    constructor(options) {
        super(options);
        bindAll(this,[
            'breakpointCHanged',
            'pauseVideo',
            'stopVideo',
            'playVideo',
            'toggleVideo',
            'dispatchVideoID',
            'loadNewVideoID',
            'handleVideoPlaying',
            'dispatchVideoPlaying',
        ]);
        // this.setVideo();
        this.isPlaying = false;
    }
    
    initialize() {
        super.initialize();
    }
    
    addEvents() {
        super.addEvents();
        
        if(this.el.querySelector('.video-poster')){
            this.el.querySelector('.video-poster').addEventListener('click', this.toggleVideo);
        }
        
        if(this.el.querySelector('.video-click-dispatcher')){
            this.el.querySelector('.video-click-dispatcher').addEventListener('click', this.dispatchVideoID);
        }
        
     
        window.addEventListener('VIDEO_PLAYING', this.handleVideoPlaying);
        if(this.el.getAttribute('data-master')){
            window.addEventListener('VIDEO_TO_PLAY', this.loadNewVideoID);
        }

        window.addEventListener('breakpointChanged', this.breakpointCHanged);
    }
    
    removeEvents() {
        super.removeEvents();
        
        if(this.el.querySelector('.video-poster')) {
            this.el.querySelector('.video-poster').removeEventListener('click', this.toggleVideo);
        }
        window.removeEventListener('VIDEO_PLAYING', this.handleVideoPlaying);
        if(this.el.getAttribute('data-master')){
            window.removeEventListener('VIDEO_TO_PLAY', this.loadNewVideoID);
        }
    }

    breakpointCHanged(e){
        if(e.detail.breakpoint == '767-'){
            this.pauseVideo();
        }
    }
    
    /**
    * Switches between playing and pausing the video based on its current state.
    */
    
    toggleVideo() {
        if (this.isPlaying) {
            this.pauseVideo();
        } else {
            this.playVideo();
        }
    }
    
    loadNewVideoID(e){
        let scope = this;
        this.videoPlayer.loadVideoById(e.detail.id);
        this.el.closest('.media').querySelector('img').src= e.detail.poster;
        this.el.closest('.media-wrapper').querySelector('.credit').innerHTML = e.detail.text;
        setTimeout(function(){scope.playVideo(true)}, 1000);
        
        //scroll to video with data-master attribute
        if(this.el.getAttribute('data-master')) {
            let offset = this.el.getBoundingClientRect().top - 100;
            window.scrollBy({top: offset, behavior: 'smooth'});
        }

    }
    /**
    * Pauses the current video player if another video player starts playing.
    * @param e
    */
    
    handleVideoPlaying(e) {
        let mediaElement = this.el.closest('.media');
        if (!mediaElement) return; // Exit if the media element is not found
        
        // If the event detail does not match this player's identifier, pause this player
        
        if (e.detail && e.detail.id !== mediaElement.getAttribute('data-video-id') && !mediaElement.dataset.autoplay) {
            this.pauseVideo();
        }
    }
    
    /**
    * Dispatches a custom event signaling that the current video has started playing, providing its identifier.
    */
    
    dispatchVideoPlaying() {
        let customEvent = new CustomEvent('VIDEO_PLAYING', {
            detail: {
                id: this.el.closest('.media').getAttribute('data-video-id'),
            },
        });
        window.dispatchEvent(customEvent);
    }
    
    /**
    * Triggered when videoplayer is ready to be interacted with.
    * autoplay property is set to true whenever we want to open the overlay on a specific media and avoid the user to press play again.
    */
    onVideoPlayerReady() {
        if (this.autoplay) {
            this.playVideo();
        }
    }
    
    dispatchVideoID(){
        let customEvent = new CustomEvent('VIDEO_TO_PLAY', {
            detail: {
                id: this.el.closest('.media').getAttribute('data-video-id'),
                poster: this.el.closest('.media').querySelector('img').getAttribute('src'),
                text: this.el.closest('.media-wrapper').querySelector('.credit').innerHTML,
            },
        });
        window.dispatchEvent(customEvent);
    }
    
    /**
    * Play video players. to be overwritten in parent class
    */
    playVideo() {
        if(this.el.querySelector('.video-poster')) {
            this.el.querySelector('.video-poster').classList.add('inactive');
        }
    }
    
    /**
    * Stop video players. to be overwritten in parent class
    */
    stopVideo() {
        this.removeEvents();
        if(this.el.querySelector('.video-poster')) {
            this.el.querySelector('.video-poster').classList.remove('inactive');
        }
    }
    
    /**
    * Pause video players. to be overwritten in parent class
    */
    pauseVideo() {
        if(this.el.querySelector('.video-poster')) {
            this.el.querySelector('.video-poster').classList.remove('inactive');
        }
    }
    
    /**
    * Prep video players. to be overwritten in parent class
    */
    setVideo() {}
}

Me.views['VideoPlayer'] = VideoPlayer;
