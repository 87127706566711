import { bindAll } from 'underscore'
class Main {
  constructor() {
    this.initialize()
  }

  initialize() {
    bindAll(this, ['resize'])
    //this class is applying css transition duration of 0 to prevent animating from resetting when refreshing the page.
    document.querySelector('body').classList.remove('preload')

    this.breakpoints = [
      window.SETTINGS.BREAKPOINTS.SM_MIN,
      window.SETTINGS.BREAKPOINTS.MD_MIN,
      window.SETTINGS.BREAKPOINTS.LG_MIN,
    ]

    this.addEvents()
  }

  addEvents() {
    window.addEventListener('resize', this.resize)
  }

  removeEvents() {
    window.removeEventListener('resize', this.resize)
  }

  resize(e) {
    let breakpoint
    let viewPortSize = this.GET_OUTER_WIDTH(document.querySelector('html'))

    if (viewPortSize <= window.SETTINGS.BREAKPOINTS.XS_MAX) {
      window.isMobile = true
    }
    if (viewPortSize <= window.SETTINGS.BREAKPOINTS.SM_MAX) {
      window.isTablet = true
    } else {
      window.isTablet = false
      window.isMobile = false
    }

    if (viewPortSize < this.breakpoints[0]) {
      breakpoint = String(this.breakpoints[0] - 1) + '-'
    }

    for (let i in this.breakpoints) {
      if (
        this.breakpoints[Number(i) + 1] != undefined &&
        viewPortSize >= this.breakpoints[i] &&
        viewPortSize < this.breakpoints[Number(i) + 1]
      ) {
        breakpoint =
          String(this.breakpoints[i]) +
          '-' +
          String(this.breakpoints[Number(i) + 1] - 1)
      }
      if (viewPortSize >= this.breakpoints[this.breakpoints.length - 1]) {
        breakpoint = String(this.breakpoints[i]) + '+'
      }
    }

    if (window.SETTINGS.BREAKPOINT != breakpoint) {
      window.SETTINGS.BREAKPOINT = breakpoint

      let eventDispatch = new CustomEvent('breakpointChanged', {
        bubbles: true,
        detail: {
          breakpoint: breakpoint,
        },
      })

      //dispatch breakpointChanged
      window.dispatchEvent(eventDispatch)
    }

    window.dispatchEvent(new Event('window.resize'))
  }

  GET_OUTER_WIDTH(element) {
    let width = element.offsetWidth
    let style = getComputedStyle(element)

    width += parseInt(style.marginLeft) + parseInt(style.marginRight)
    return width
  }
}

document.addEventListener('DOMContentLoaded', function () {
  new Main()
})
