import { ViewBasic } from '@quatrecentquatre/manage-me'
import { bindAll } from 'underscore'

export class Header extends ViewBasic {
  constructor(options) {
    super(options)
    this.isMenuOpen = false // Flag to track if menu is open or closed
    this.menu = this.el.querySelector('.hamburger-menu')
  }

  initialize() {
    bindAll(this, ['checkScroll'])
    this.prevScrollpos = window.scrollY
    this.addEvents()
  }

  addEvents() {
    let scope = this
    // Add click event listener to hamburger menu icon
    this.menu.addEventListener('click', this.toggleMenu.bind(this))
    this.el
      .querySelector('.backdrop')
      .addEventListener('click', this.toggleMenu.bind(this))
    window.addEventListener('scroll', this.checkScroll)
  }

  removeEvents() {
    // Remove click event listener from hamburger menu icon
    this.menu.removeEventListener('click', this.toggleMenu.bind(this))
    window.removeEventListener('scroll', this.checkScroll)
  }

  checkScroll() {
    let currentScrollPos = window.scrollY
    if (this.prevScrollpos > currentScrollPos || currentScrollPos < 50) {
      this.el.classList.remove('hidden-scroll')
    } else {
      this.el.classList.add('hidden-scroll')
    }

    if (currentScrollPos <= 20) {
      this.el.classList.add('top-scroll')
    } else {
      this.el.classList.remove('top-scroll')
    }

    if (document.querySelector('.hero-homepage')) {
      if (
        currentScrollPos <=
        document.querySelector('.hero-homepage').offsetHeight -
          this.el.offsetHeight
      ) {
        this.el.classList.add('homepage')
      } else {
        this.el.classList.remove('homepage')
      }
    }

    this.prevScrollpos = currentScrollPos
  }

  toggleMenu() {
    this.menuOpen = !this.menuOpen
    const menuItems = this.el.querySelector('.menu-items')
    const hamburgerIcon = this.el.querySelector('.hamburger-icon')

    if (this.menuOpen) {
      // Apply styles for menu open state
      this.el.classList.add('opened')
      menuItems.classList.add('active')
      hamburgerIcon.classList.add('active')
    } else {
      // Apply styles for menu closed state
      this.el.classList.remove('opened')
      menuItems.classList.remove('active')
      hamburgerIcon.classList.remove('active')
    }
  }
}

Me.views['Header'] = Header
